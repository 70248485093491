<template>
  <div>
    <VueTelInput
      v-model="phone"
      class="form-control"
      defaultCountry="GB"
      :preferredCountries="['GB']"
      :inputOptions = "{placeholder: 'Enter your phone number'}"
      @input="onInput"
      name="phoneInput"
      v-bind:class="{'is-invalid': faulty}"
    ></VueTelInput>
    <div class="invalid-feedback">Enter a valid phone number</div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: 'PhoneInputField',
  props: ['value'],
  components: {
    VueTelInput
  },
  data () {
    return {
      phoneObject: {},
      phone: this.value
    }
  },
  computed: {
    faulty() {
      if (this.phoneObject?.valid === false) {
        return true
      } else {
        return false
      }
    },
    inputVal: {
      get() {
        return this.value;
      }
    }
  },
  created() {
    this.phone = this.value
  },
  methods: {
    onInput(value, phoneObject) {
      this.phoneObject = phoneObject
      var phoneData = {
        number: phoneObject.number,
        valid: phoneObject.valid
      }
      console.log('emit', phoneData)
      this.$emit('input', phoneData)
    },
  },
  watch: {
    value() {
      if (typeof this.value === 'string') {
        this.phone = this.value
      }
    }
  }
}
</script>
